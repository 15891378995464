<template>
  <PageContent>
    <div class="level-left level-item title">{{ $t('billPayApproval.title') }}</div>
    <div class="box is-4" v-if="true">
      <div class="tile is-ancestor">
        <div class="tile is-vertical is-12">
          <div>
            <article>
              Coming Soon
            </article>
          </div>
        </div>
      </div>
    </div>
  </PageContent>
</template>

<script>
import PageContent from '@/containers/PageContent'
//import kms from '@/services/kms'
import { mapState, mapGetters, mapActions } from 'vuex'
import { ToastProgrammatic as Toast } from 'buefy'
import moment from 'moment'

function notifyError(e) {
  Toast.open({
    duration: 7000,
    message: e.message || e,
    position: 'is-bottom',
    type: 'is-danger'
  })
}

let DEFAULT_FORM_VALUES = {
  name: '',
  email: '',
  status: ''
}

export default {
  name: 'BillPayApproval',
  components: {
    PageContent
  },
  data: function() {
    return {
      formComplete: false,
      formData: {
        ...DEFAULT_FORM_VALUES
      },
      loading: true,
      isDebug: true,
      formatDateFn: function(value) {
        return value && value != undefined
          ? moment(value.toString(), 'YYYY/MM/DD HH:mm:ss')
              .format('MM-DD-YYYY')
              .toString()
          : ''
      }
    }
  },

  computed: {
    ...mapState({
      hoaId: state => state.user.selectedHoaId,
      ...mapGetters('user', ['authUser'])
    })
  },

  mounted() {
    this.loading = true

    if (this.isDebug == true) console.debug('gen settings authUser=' + this.authUser)

    this.reload()

    this.loading = false
  },

  watch: {
    hoaId() {
      this.reload()
    }
  },

  methods: {
    ...mapActions('user', ['selectHoaId']),

    async reload() {
      this.loading = true

      this.formData = { ...DEFAULT_FORM_VALUES }
      if (this.$refs.form) {
        this.$refs.form.reset()
      }

      this.loading = false
    },

    async getAccountById() {
      try {
        // const getAccount = await kms.get(`/Account/${this.authUser.accountID}`)

        // if (this.isDebug == true) console.debug('getAccount=' + JSON.stringify(getAccount))

        // if (getAccount && getAccount != undefined) {
        //   this.formData.accountID = getAccount.accountID
        //   this.formData.name = getAccount.name
        //   this.formData.email = getAccount.email
        //   this.formData.status = getAccount.status

        if (this.isDebug == true)
          console.debug('gen settings formData=' + JSON.stringify(this.formData))
        //}
      } catch (e) {
        notifyError(e)
      }
    }
  },
  i18n: {
    messages: {
      en: { billPayApproval: { title: 'Bill Pay Approval' } }
    }
  }
}
</script>

<style lang="scss">
textarea {
  min-width: 600px !important;
}

button {
  padding: 5px;
}

#app {
  font-family: 'Avenir', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin-top: 60px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  > div {
    width: 940px;
  }

  .app-properties {
    > div {
      margin: 0 8px 8px 0;
      font-style: normal !important;

      > label {
        display: block;
      }
      > span {
        font-style: normal !important;
      }
      .vue-quick-edit {
        display: inline-block;
        margin-left: 8px;
        font-style: normal !important;
      }
    }
  }
}
</style>
